import { AdUnitContext } from 'googlead/components/AdUnitContext';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { HeadMeta } from 'components/common/HeadMeta';
import { HeaderNav, HeaderNavCurLink } from 'components/common/HeaderNav';
import { Footer } from 'components/blocks/Footer';
import React, { useEffect } from 'react';
import { GetStaticProps } from 'next';
import {
  fetchBackgroundImages,
} from 'api/home/static';
import {
  IBackgroundImages,
} from 'api/home/types';
import { Hero404 } from 'components/404';
import { Analytics, AnalyticsContext } from 'util/analytics';
import { useQueryWithInitialValue } from 'util/useQueryWithInitialValue';
import { propsWithI18N } from 'util/propsWithI18n';
import { wrapStaticProps } from 'util/wrapProps';

export type Props = {
  backgroundImage: IBackgroundImages;
};

export const DEBUG_TAG = '404-page';
export const TEMPLATE_ID = '1.2';
export const TEMPLATE_NAME = '404 Page';

export default function NotFound({
  backgroundImage,
}: Props) {
  useEffect(() => {
    Analytics.notFound({ templateName: TEMPLATE_NAME, templateId: TEMPLATE_ID }, { url: global.location?.href });
  }, []);
  const { t: seoT } = useOTSTranslation('seo');

  const {
    current: curBackgroundImage, hasFreshData: isImageFresh,
  } = useQueryWithInitialValue(['skireport-fetchBackgroundImages'],
    async () => fetchBackgroundImages(),
    backgroundImage);

  return (
    <AnalyticsContext templateId={TEMPLATE_ID} templateName={TEMPLATE_NAME}>
      <AdUnitContext
        adType="other"
        adTKey="error"
      >
        <div style={{ background: '#f4f5f5' }}>
          <HeadMeta
            title={seoT('Other.404.title')}
            description={seoT('Other.404.description')}
          />
          <HeaderNav current={HeaderNavCurLink.MAIN} />
          <Hero404
            loading={!isImageFresh}
            backgroundImage={curBackgroundImage}
          />
          <Footer />
        </div>
      </AdUnitContext>
    </AnalyticsContext>
  );
}

export const fetchProps = async () => {
  const backgroundImage = await fetchBackgroundImages();
  return {
    backgroundImage,
  };
};

// This function gets called at build time
export const getStaticProps: GetStaticProps<Props> = propsWithI18N(wrapStaticProps(fetchProps));
